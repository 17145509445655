@media only screen and (max-width: 800px) {
}

@media only screen and (min-width: 801px) {
}

.link {
    cursor: pointer;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 20px;
    
}
.flag-icon {
    margin-left: .2em;
    margin-right: .2em;
}