.detailsPanel {
    text-align: center;
    min-width: 250px;
    width: 100%;
    display: grid;
    grid-gap: 10px;
    vertical-align: middle;
}
.detailNav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}