@media only screen and (max-width: 800px) {
    .SelectionMap {
        width: 100%;
        height: 200px;
        position: relative;
        margin-top: 30px;
    }

    .map-control {
        display: inline;
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translate(-50%, -90%);
        z-index: 100;
        background-color: white;
        vertical-align: middle;
        font-size: .5em;
    }
}

@media only screen and (min-width: 801px) {
    .SelectionMap {
        width: 100%;
        height: 400px;
        position: relative;
    }
    
    .map-control {
        display: inline;
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translate(-50%, -90%);
        z-index: 100;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        vertical-align: middle;
    }
}