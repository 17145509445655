.Post-Image {
    width: 50px;
    height: 50px;
    margin: auto;
  }
.cardGrid {
  padding-top: 1em;
  padding-bottom: 2em;
  margin: auto;
}
.no-posts {
  font-size: 26px;
  padding: 20px;
}