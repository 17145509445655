.statsPanel {
  text-align: center;
  min-width: 275px;
  width: 100%;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
  background-color: #3E442B;
  padding: .2em;
}
.statsContent {
  color: white;
  text-align: left;
  padding-left: .5em;
  padding-top: .5em;
  padding-bottom: .5em;
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  .statsPanel {
    display: grid;
  }
  .statsContent {
    font-size: 1.0em;
  }

}

@media only screen and (min-width: 801px) {
  .statsPanel {
    display: flex;
    flex-direction: row;
  }
  .statsContent {
    font-size: 1.3em;
  }
}

.statsLabel {
  font-size: 1.0em;
  color: white;
  text-align: right;
  padding-left: 2em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.color1 {
    color: #6f584b
  }
  .color2 {
    color: #4e6e5d
  }
  .color3 {
    color: #1c6e8c
  }
  .color4 {
    color: #4d5057
  }
  .color5 {
    color: #000000
  } 