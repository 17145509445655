@media only screen and (max-width: 800px) {
    #arriveCol, #departCol, #codeCol {
        display: none;
    }
    .location-table {
        min-width: 250px;
    }
}
    
@media only screen and (min-width: 801px) {
    .location-table {
        min-width: 650px;
    }
}
  

.root {
    width: '100%';
    margin-top: 1em;
    overflow-x: auto;
}